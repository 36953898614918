import QuickReply from "@shares/components/QuickReply";
import { useEffect, useRef, useState } from "react";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import { MessageItem } from "./MessageItem";
import { useMessageBox } from "./useMessageBox";
import { EMessageType } from "@types";
import { isEmpty } from "lodash";

export const MessageBox = () => {
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const {
    firstItemIndex,
    messages,
    isUserSendMessage,
    isFirstFetching,
    isLoadingMore,
    onLoadMoreMessages,
    onSendAction,
  } = useMessageBox();

  return (
    <main className="flex-1 bg-[#eeeded]">
      <Virtuoso
        alignToBottom
        ref={virtuosoRef}
        data={messages}
        firstItemIndex={firstItemIndex}
        defaultItemHeight={113}
        atTopStateChange={(atTop) => {
          if (isFirstFetching) return;
        }}
        itemContent={(_, message) => {
          return <MessageItem message={message} onSendAction={onSendAction} />;
        }}
        components={{
          Footer: () => {
            const lastMsg = messages[messages.length - 1];
            if (!lastMsg) return null;
            if (lastMsg.type === EMessageType.ATTACHMENT) {
              return null;
            }
            const hasQuickReply = !isEmpty(lastMsg.quickReply);
            if (!hasQuickReply) return null;
            return (
              <div>
                <QuickReply
                  quickReply={lastMsg.quickReply}
                  onSendAction={onSendAction}
                />
              </div>
            );
          },
        }}
        followOutput={(isAtBottom: boolean) => {
          // if sender is user force scroll to bottom
          if (isUserSendMessage) {
            return true;
          }
          // user stick at bottom then go to bottom when new message
          if (isAtBottom) {
            return true;
          }
          return false;
        }}
      />
    </main>
  );
};
